import { defineStore } from 'pinia';
import { useApi } from '~/composables';
import articlesRootCategoriesGql from '../queries/articlesRootCategories.gql';
import { ArticleCategory, ArticlesRootCategoriesQuery } from '../types';

export const useArticlesStore = defineStore('articles', {

  state: () => ({
    rootCategories: [] as ArticleCategory[],
  }),
  actions: {
    async fetchRootCategories() {
      const { query } = useApi();
      const { data } = await query<ArticlesRootCategoriesQuery>(articlesRootCategoriesGql);

      this.rootCategories = data?.getArticlesRootCategories?.items;
    },
  },
});
