



























































import { computed, defineComponent, useFetch } from '@nuxtjs/composition-api';
import { SfFooter, SfImage, SfLink } from '@storefront-ui/vue';
import { useUser } from '~/composables';
import { addBasePath } from '~/helpers/addBasePath';
import { useArticlesStore } from '~/modules/articles/stores/articles';

export default defineComponent({
  components: {
    SfFooter,
    SfImage,
    SfLink,
  },
  setup() {
    const { isAuthenticated } = useUser();
    const articlesStore = useArticlesStore();
    const articlesRootCategories = computed(() => articlesStore.rootCategories);
    const accountLink = isAuthenticated.value
      ? {
        title: 'My Account',
        link: '/customer/my-profile',
      }
      : {
        title: 'My Account',
        link: '/login',
      };

    useFetch(async () => {
      await articlesStore.fetchRootCategories();
    });

    return {
      isAuthenticated,
      accountLink,
      addBasePath,
      currentYear: new Date().getFullYear(),
      articlesRootCategories,
    };
  },
  data() {
    return {
      infoLinks: [
        {
          title: 'About us',
          link: '/om-oss',
          class: '',
        },
        {
          title: 'This is how it works',
          link: '/sa-fungerar-det',
          class: '',
        },
        {
          title: 'Where you can find us',
          link: '/provtagningsstallen',
          class: 'mb-base',
        },
        {
          title: 'Fear of needles',
          link: '/stickradd',
          class: '',
        },
        {
          title: 'Our team',
          link: '/vart-team',
          class: '',
        },
      ],
    };
  },
});
