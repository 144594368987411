export default `query articlesRootCategories {
  getArticlesRootCategories {
    total_count
    items {
      id
      name
      slug
      sub_categories {
          total_count
          items {
              id
              name
              slug
              description {
                  html
              }
          }
      }
    }
  }
}
`;
